import(/* webpackMode: "eager", webpackExports: ["PlasmicClientRootProvider"] */ "/vercel/path0/apps/frontend/plasmic-init-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PlasmicComponent"] */ "/vercel/path0/node_modules/.pnpm/@plasmicapp+loader-nextjs@1.0.398_next@14.2.5_@babel+core@7.24.8_@opentelemetry+api@1.9.0_bab_gpxjt73xm3uza3me75in7d3psm/node_modules/@plasmicapp/loader-nextjs/dist/index.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["ExtractPlasmicQueryData"] */ "/vercel/path0/node_modules/.pnpm/@plasmicapp+nextjs-app-router@1.0.11_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@plasmicapp/nextjs-app-router/dist/index.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["PlasmicPrepassContext","HeadMetadataContext","Provider"] */ "/vercel/path0/node_modules/.pnpm/@plasmicapp+query@0.1.79_react@18.3.1/node_modules/@plasmicapp/query/dist/index.esm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.8_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_xnsjiwzacrxihpmwqcpjlyfvqu/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.8_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_xnsjiwzacrxihpmwqcpjlyfvqu/node_modules/next/dist/client/script.js");
